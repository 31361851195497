import moment from "moment";
import React from "react";
import Slider from "react-slick";
import Divider from "@mui/material/Divider";

import "./styles.scss";

const RegistrationSection = ({ activeEvents }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: activeEvents?.length < 3 ? activeEvents.length : 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="available-sessions">
        <Slider {...settings}>
          {activeEvents?.map((event) => {
            return (
              // <div>
              <React.Fragment key={event.id}>
                <NewRegCard event={event} />
              </React.Fragment>
              //</div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

const RegistrationCard = ({ event }) => {
  return (
    <div className="ks-live-registration-card">
      <div className="session-banner">
        <img
          src={event?.banner}
          alt={`${event?.title} banner image`}
          loading="lazy"
        />
      </div>
      <div className="session-details-main">
        <div className="session-meta">
          <div>
            <b>Webinar: </b> {event?.title ?? "--"}
          </div>
          <div>
            <b>Time:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("LT")
              : "--"}
          </div>
          <div>
            <b>Date:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("LL")
              : "--"}
          </div>
          <div>
            <b>Speaker:</b> {event?.speakers}
          </div>
        </div>
        <hr style={{ marginBottom: "unset" }} />
        <div className="session-about-info">{event?.description}</div>
        <button
          className="register-btn"
          onClick={() =>
            window.open(`/kraftshala-live/register/${event?.id}`, "_top")
          }
        >
          Register Now
        </button>
      </div>
    </div>
  );
};

const NewRegCard = ({ event }) => {
  return (
    <div className="ks-live-registration-card">
      <div className="session-banner">
        <img
          src={event?.banner}
          alt={`${event?.title} banner image`}
          loading="lazy"
        />
      </div>
      <div className="session-details-main">
        <div className="session-meta">
          {/* <div>
              <b>Webinar: </b> {upcomingEvent?.title ?? "--"}
          </div> */}
          <div>
            <b>Date:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("ll")
              : "--"}
          </div>
          <div>
            <b>Day:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("dddd")
              : "--"}
          </div>
          <div>
            <b>Time:</b>{" "}
            {moment(event?.start_date_time).isValid()
              ? moment(event?.start_date_time).format("LT")
              : "--"}
          </div>
        </div>
        <Divider
          orientation="vertical"
          sx={{ width: "1px !important", height: "6em" }}
        />
        <div className="session-about-info">{event?.description}</div>
      </div>
      <button
        className="register-btn"
        onClick={() =>
          window.open(`/kraftshala-live/register/${event?.id}`, "_top")
        }
      >
        Register Now
      </button>
    </div>
  );
};

export default RegistrationSection;
