import React from "react";

function scrollToSection(e) {
  e.preventDefault();

  const targetText = e.target.textContent;
  // Find the heading element with matching text content
  const headings = document.querySelectorAll("h2");
  let targetElement;

  headings.forEach((heading) => {
    if (heading.textContent === targetText) {
      targetElement = heading;
    }
  });

  if (targetElement) {
    const topPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset - 150;

    // Scroll to the calculated position with smooth behavior
    window.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  }
}

export const TableOfContents = ({ toc, link, pageURL }) => {
  return (
    <>
      {" "}
      <div className="header">Table of contents</div>
      <ul>
        {toc.map(
          (item) =>
            item.depth === 2 && (
              <li className="tocItem" key={item.url}>
                <a href={item.title} onClick={scrollToSection}>
                  {item.depth === 2 && item.title}
                </a>
              </li>
            )
        )}
      </ul>
      <hr />
      
    </>
  );
};
