import React from 'react';

const Card = (props) => {
  return (
    <div className="sidebar-card">
      <div className="sidebar-title">
        {props.title}
        <span className="text-strokes">
          <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t5vrsy.webp" alt="strokes" />
        </span>
      </div>
      <div className="sidebar-trademark">{props.trademark}</div>
      <div className="sidebar-about">
        {/* {props.about} */}
        <ul className="feature-list">
          {props.list.map((el) => {
            return <li>{el}</li>;
          })}
        </ul>
      </div>
      <div className="sidebar-cta">
        <a
          href={props.link}
          className="becomeMarketer"
          target="_blank"
          rel="noreferrer"
        >
            Become A Marketer Today
        </a>
      </div>
    </div>
  );
};

export default Card;