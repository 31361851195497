import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";

export const Share = ({link}) => {
  return (
    <div>
      <div className="share">SHARE</div>
      
      {/* <a
  href={`https://www.youtube.com/user/${encodeURIComponent(link)}`}
>
  <YouTubeIcon
    sx={{
      color: "grey",
      marginX: "10px",
      ":hover": {
        color: "#F1AE13",
      },
    }}
  />
</a> */}
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${link}`}
      >
        <LinkedInIcon
          sx={{
            color: "grey",
            marginX: "10px",
            ":hover": {
              color: "#F1AE13",
            },
          }}
        />
      </a>
      <a href={`https://wa.me/?text=${encodeURIComponent(link)}`}>
        <WhatsAppIcon
          sx={{
            color: "grey",
            marginX: "10px",
            ":hover": {
              color: "#F1AE13",
            },
          }}
        />
      </a>
      <a href={`https://twitter.com/intent/tweet?url=${link}`}>
        <TwitterIcon
          sx={{
            color: "grey",
            marginX: "10px",
            ":hover": {
              color: "#F1AE13",
            },
          }}
        />
      </a>
      <CopyToClipboard text={link}>
        <LinkIcon
          sx={{
            color: "grey",
            marginX: "10px",
            ":hover": {
              color: "#F1AE13",
            },
          }}
        />
      </CopyToClipboard>
    </div>
  );
};
